"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const lit_element_1 = require("lit-element");
exports.styles = lit_element_1.css `
    :host {
        --modal__header--color: var(--primary-color);
        --modal__header__sub-title--color: var(--primary-color);
        --modal__header--text-align: left;
        --modal--font-familiy: var(--body-font-family);
        --modal__header--padding: 0 0 0 24px;
    }
`;
exports.default = {
    // styles
    styles: exports.styles,
};
