"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const lit_element_1 = require("lit-element");
exports.styles = lit_element_1.css `
    :host {
        --button-component_small--width: inherit;
        --button-component--border-radius: var(--border-radius);
        --button-component--background: linear-gradient(
            90deg,
            #003c85 0,
            #1e87cc
        );
        --button-component--padding: 12px 20px;
        --font-size: 16px;
        --button-component--font-weight: 700;
        --button-component_small--padding: 12px 20px;
        --button-component--height: 44px;
        --button-component--width: inherit;
        --button-component--inner-padding__left: inherit;
        --button-component--inner-padding__right: 12px;
        --button-component--flex-grow: unset;
        --button-component--align-item: left;
        --button-component--line-height: unset;
        display: inline-block;
    }
    :host([theme='primary']) button {
        display: flex;
        align-items: center;
    }
    :host([theme='secondary']) {
        --button-component__secondary--border: 2px solid var(--primary-color);
        --button-component__secondary--color: var(--primary-color);
    }
`;
exports.default = {
    // styles
    styles: exports.styles,
    iconURL: '/vendor_theme/assets/illustrations/arrow-right-white.svg',
};
