'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
// @ts-nocheck
exports.default = {
    appTitle: 'ANWB Energie',
    // Je kunt hier een redirect invullen. Die is dan voor geauthenticeerde,
    // als niet geauthenticeerde users.
    logoRedirect: 'https://www.anwb.nl/huis/energie',
    electricityAccentColor: '#3F87C7',
    electricityProductionAccentColor: '#3F87C7',
    gasAccentColor: '#3f87c7',
    savingsAccentColor: '#3f87c7',
    enableFirebaseAnalytics: true,
    energyCostAccentColor: '#3f87c7',
    subtitle: '',
    // Je kunt hier een redirect invullen.
    // Die is dan voor geauthenticeerde, als niet geauthenticeerde users.
    logoAppLink: '',
    phoneNumber: '088 2692266',
    appDomain: 'ANWB Energie',
    vendorID: '699027a3-1086-46f5-9dcc-add51ca2518e',
    showCurrentPrices: true,
    analytics: 'https://cdn.blueconic.net/anwb.js',
    smartCharging: false,
    onboarding: true,
    ABTestURL: `https://cdn.optimizely.com/js/21522380468.js`
};