'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
// @ts-nocheck
exports.default = {
    challengeTabTitle: 'Challenge',
    rankingTabTitle: 'Ranglijst',
    compareTabTitle: 'Vergelijker',
    challengeGridHeaderColumnTitleFirst: 'Rang',
    challengeGridHeaderColumnTitleSecond: 'Deelnemer',
    challengeGridHeaderColumnTitleThird: 'Besparingsronde',
    challengeGridHeaderColumnTitleFourth: 'Puntenverdeling',
    rankingGridHeaderColumnTitleFirst: 'Rang',
    rankingGridHeaderColumnTitleSecond: 'Deelnemer',
    rankingGridHeaderColumnTitleThird: 'Besparingstotaal',
    rankingGridHeaderColumnTitleFourth: 'Puntentotaal',
    competitionComperisionInfo: `Staan de waardes rood gekleurd dan is er sprake 
    van overproductie van CO₂ of het verbruik staat boven de verwachting.`
};