"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const lit_element_1 = require("lit-element");
exports.styles = lit_element_1.css `
    :host {
        --tooltip--width: 462px;
        --tooltip--side-padding: 12px;
        --tooltip--top-bot-padding: 12px;
    }

    .tooltiptext {
        font-family: var(--app-tertiary-font-family);
        font-size: 12px;
        color: white;
        letter-spacing: -0.25;
        line-height: 1.5;
    }

    :host(:hover) .tooltiptext {
        box-shadow: none;
        background-color: #003d86;
        border-radius: var(--border-radius);
        border: none;
    }
`;
exports.default = {
    // styles
    styles: exports.styles,
};
