'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
// @ts-nocheck
exports.default = {
    chartThemePrimaryColor: '#003c85',
    chartThemeSecondaryColor: '#444',
    chartThemeSecondaryLineColor: '#ddd',
    chartThemePrimayLineTension: 0,
    chartThemeSecondaryLineTension: 0.5
};