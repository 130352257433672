"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const lit_element_1 = require("lit-element");
exports.styles = lit_element_1.css `
    :host {
        --hv-input_-_input_-_max-width: auto;
    }

    ::slotted(input) {
        border: 2px solid #bfdced;
        border-radius: var(--border-radius);
        background-color: var(--background-color);
        max-width: 440px !important;
        color: var(--primary-color);
    }

    ::slotted(input:hover) {
        border-color: #0096db;
    }

    ::slotted(label) {
        font-family: var(--app-tertiary-font-family);
        font-size: var(--font-size);
        color: var(--primary-color);
        padding: 0 !important;
        line-height: 24px;
    }

    ::slotted(lion-validation-feedback) {
        padding: 0;
    }
`;
exports.default = {
    // styles
    styles: exports.styles,
};
