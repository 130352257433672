'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
// @ts-nocheck
exports.default = {
  menuPositionVariant: 1,
  menuSelectedPostAgree: 3,
  menuSelectedPreAgree: 2,
  /**
   * Menu position variants
   * 1. Sign mandate and pay for meter on page
   * 2. Show button to go to app and sign mandate and pay for meter in app
   */
  postAgreeVariant: 1,
  termsOfServiceURL: 'https://anwb.nl/huis/energie/voorwaarden/',
  vendor: 'ANWB Energie',
  vendorPhoneNumber: '088 2692266',
  vendorTermsAndPrivacyTitle: 'Voorwaarden',
  compactTerms: true,
  // eslint-disable-next-line max-len
  incassoInformationURL: 'https://www.anwb.nl/huis/energie/anwb-energie/voorwaarden'
};