"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const lit_element_1 = require("lit-element");
exports.styles = lit_element_1.css `
    p {
        font-size: 14px;
        line-height: 24px;
        font-family: var(--app-tertiary-font-family);
    }
    .monthly-pricing {
        font-size: 18px;
    }
    .total {
        font-family: var(--body-font-family);
    }
    .btnPrimary,
    #confirmButton {
        cursor: pointer;
        padding: 12px 20px;
        margin: 24px 0 0;
        align-self: flex-start;
        height: 44px;
        line-height: initial;
        border-radius: var(--border-radius);
        font-family: var(--body-font-family);
        color: var(--white);
        text-align: center;
        text-transform: none;
        font-size: var(--font-size);
        font-weight: bold;
        background-color: var(--primary-color);
        background-image: linear-gradient(to right, #003c85 0, #1e87cc 100%);
    }
    button-component[theme='secondary'] {
        margin-left: 24px;
    }

    .monthly-payment-header {
        font-family: var(--primary-font-family);
    }
    h1 {
        font-weight: bold !important;
        font-style: italic !important;
    }
`;
const introductionText = 
// eslint-disable-next-line max-len
'Ook zonder je verbruik te wijzigen kun je op ieder moment het maandbedrag opnieuw berekenen op basis van de laatste prijsontwikkelingen in de markt.';
exports.default = {
    // styles
    styles: exports.styles,
    faq: [
        [
            'Hoe berekenen jullie het maandbedrag?',
            // eslint-disable-next-line max-len
            'Het maandelijkse voorschotbedrag is een momentopname waarbij de kosten van je verbruik zo goed mogelijk zijn geschat voor de komende 12 maanden. Dat gebeurt aan de hand van de huidige markt in combinatie met een prognose van de ontwikkeling van de energieprijzen en die wordt iedere dinsdag bijgewerkt.',
        ],
        [
            'Waarom is het belangrijk dat ik regelmatig mijn maandbedrag opnieuw bereken?',
            // eslint-disable-next-line max-len
            'Door omstandigheden op de energiemarkt kunnen de inkoopprijzen hoger of lager uitvallen, maar dat geldt natuurlijk ook voor je verbruik. Het is niet nodig om maandelijks te veel te betalen, maar we willen ook niet dat je verrast wordt met een hoge jaarrekening. Daarom raden we je aan eens in de 3 maanden te controleren of je maandbedrag en het verbruik niet te veel afwijken. Wij houden dit uiteraard ook voor je in de gaten.',
        ],
    ],
    showIntroduction: true,
    monthlyIntroduction: introductionText,
    showArrow: true,
};
