'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
// @ts-nocheck
exports.default = {
    title: 'Is dit niet je eerste energiecontract?',
    bodyText: `Log in met je ANWB Energie-account en we laden alle bestaande gegevens alvast in.`,
    buttonExistingCustomerText: 'Inloggen en gegevens ophalen',
    buttonNewCustomerText: 'Verder met nieuwe aanvraag',
    backButtonText: 'Terug'
};