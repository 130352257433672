"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    widgetEnable: true,
    elecCostSettingsMap: {
        Stroomkosten: ['Dagprijs EPEX'],
        'Variabele kosten': ['Energiebelasting', 'Inkoopkosten', 'ODE'],
        'Vaste kosten': [
            'Netbeheerkosten stroom',
            'Vaste leveringskosten',
            'Vermindering Energiebelasting',
        ],
    },
    elecDescriptionsMap: {
        Stroomkosten: 'De inkoopprijs van het stroomverbruik. Incl. btw, maar excl. belastingen en inkoopkosten.',
        'Variabele kosten': 'Dit zijn de verbruiksafhankelijke kosten per kWh.',
        'Vaste kosten': 'Dit zijn kosten die iedere maand hetzelfde zijn, maar zijn verdeeld over alle dagen van de maand.',
    },
    gasCostSettingsMap: {
        Gaskosten: ['Dagprijs LEBA'],
        'Variabele kosten': ['Energiebelasting', 'Inkoopkosten', 'ODE'],
        'Vaste kosten': ['Netbeheerkosten gas', 'Vaste leveringskosten'],
    },
    gasDescriptionsMap: {
        Gaskosten: 'De inkoopprijs van het gasverbruik. Incl. btw, maar excl. belastingen en inkoopkosten.',
        'Variabele kosten': 'Dit zijn de verbruiksafhankelijke kosten per m3.',
        'Vaste kosten': 'Dit zijn kosten die iedere maand hetzelfde zijn, maar zijn verdeeld over alle dagen van de maand.',
    },
};
