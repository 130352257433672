"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const lit_element_1 = require("lit-element");
exports.styles = lit_element_1.css `
    :host {
        --container--background-color: var(--app-light-blue);
        --container--color: var(--primary-color);
    }
`;
exports.default = {
    // styles
    styles: exports.styles,
};
