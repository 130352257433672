"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const lit_element_1 = require("lit-element");
exports.styles = lit_element_1.css `
    flex-rates-component {
        border: 2px solid #bfdced;
        border-radius: var(--border-radius);

        /* variables */
        --text-color: #285685;
        --primary-color: #003d86;
        --bar-color: var(--primary-color);
        --background-color: transparant;
        --font-family: verdana, arial, sans-serif;

        /* arrow icon styles */
        --arrow--background-color: #003d86;
        --arrow__container--height-width: 28px;

        /* custom styles */
        --font-size: 15px;
        --title--font-weight: 700;

        /* graph */
        --bar-average-color: #0096da;

        /* header */
        --header__mobile--padding: 0 20px;

        /* footer */
        --footer--background-color: var(--white);
        --footer-text-active-color: #0096da;
        --footer--font-size: var(--font-size);

        /* disclaimer styles */
        --disclaimer-color: #285685;
        --disclaimer--font-size: 11px;

        /* loader styles */
        --flex-loader-color: var(--text-color);

        /* tooltip */
        --icon--height-width: 26px;
        --information-button--color: #0096da;
        --information-button--color-hover: var(--primary-color);

        /* legenda */
        --chart-title--font-size: 15px;
        --legenda-h1-size: 18px;
        --legenda-h1-color: #003d86;
        --legenda-font-size: var(--font-size);
        --legenda-max-width: 760px;
        --legenda-font-color: var(--text-color);
        --legenda-description-color: #003d86;

        /* error */
        --error-color: #e41;
        --error-font-size: 12px;
    }

    @media only screen and (max-width: 550px) {
        :host {
            padding: 0 16px;
        }
    }
`;
exports.default = {
    // styles
    styles: exports.styles,
    barColor: '#003d86',
    barHoverColor: '#0096da',
    lineColor: '#0096da',
    axisLabelColor: '#285685',
    legendaInformation: ``,
};
