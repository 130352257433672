"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const lit_element_1 = require("lit-element");
exports.styles = lit_element_1.css `
    :host,
    :host(:hover),
    :host(:active),
    :host([active]) {
        --font-color: #fff;
        --hv-button-text-transform: default;

        background-image: linear-gradient(90deg, #2664a6 0, #269ad9) !important;
        cursor: pointer;
        padding: 12px 20px;
        margin: 24px 0 0;
        align-self: flex-start;
        height: 44px;
        line-height: normal;
        border-radius: var(--border-radius);
        font-family: var(--body-font-family);
        color: var(--white);
        text-align: center;
        text-transform: none;
        font-size: var(--font-size);
        font-weight: 700;
        display: inline-flex;
    }

    .button-content {
        display: inline-flex;
    }

    .button-content::after {
        background: url('/vendor_theme/assets/illustrations/arrow-right-white.svg')
            0 0 no-repeat;
        background-size: 15px;
        content: '';
        height: 15px;
        width: 16px;
        margin-left: 12px;
        margin-top: 2px;
        display: block;
    }
`;
exports.default = {
    // styles
    styles: exports.styles,
};
