"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    // styles
    placeholder: {
        email: false,
        password: false,
    },
    showLabel: true,
    styleObject: {
        paddingBottom: '10px',
    },
};
