'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
// @ts-nocheck
exports.default = {
    title: 'Inloggen op ANWB Energie',
    body: '', // "Log hieronder in op je account met een e-mailadres en wachtwoord."
    emailTitle: 'E-mailadres',
    emailPlaceholder: 'Bijv.: voorbeeld@domein.nl',
    passwordTitle: 'Wachtwoord',
    passwordPlaceholder: 'Bijv.: A2richting-Utrecht',
    submit: 'Inloggen',
    forgotPassword: 'Wachtwoord vergeten?',
    newCustomer: 'Nog geen klant? Bereken je energievoordeel',
    disableNewCustomerOnboardingButton: false
};