"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
// @ts-nocheck
exports.default = {
  /**
   * colors key value object
   * 1. options are info, warning, error and succes.
   * 2. dont forget to set the type in the frontend core if you want to change a specific message.
   */
  colorsKeyValue: {}
};