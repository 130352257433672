"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    // styles
    label: {
        submit: 'Stuur e-mail',
    },
    placeholder: {
        email: false,
    },
    showLabel: true,
    styleObject: {
        paddingBottom: '10px',
    },
};
