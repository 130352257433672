'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
// @ts-nocheck
exports.default = {
    welcomeVariant: 2,
    faqURL: 'https://www.anwb.nl/huis/energie/anwb-energie/veelgestelde-vragen',
    priceCapURL: 'https://www.anwb.nl/huis/energie/het-prijsplafond-voor-energie'
};