'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = {
    /**
     * Properties used for app pages
     * 'start' for example is the apps home page
     */
    start: {
        title: null,
        body: null,
        /**
         * illustration
         * name of image file for example: 'start.svg'
         * @type {string | null}
         */
        illustration: 'start.svg',
        primaryBtnTitle: null,
        primaryBtnTarget: null,
        secondaryBtnTitle: null,
        secondaryBtnTarget: null
    },
    smartMeter: {
        title: null,
        body: null,
        /**
         * illustration
         * name of image file for example: 'start.svg'
         * @type {string | null}
         */
        illustration: 'slimmemeters.svg',
        primaryBtnTitle: null,
        secondaryBtnTitle: null,
        dialog: {
            title: null,
            body: null,
            primaryBtnTitle: null
        }
    },
    termsAndConditions: {
        title: null,
        body: null,
        /**
         * illustration
         * name of image file for example: 'start.svg'
         * @type {string | null}
         */
        illustration: 'slimmemeters.svg',
        primaryBtnTitle: null,
        secondaryBtnTitle: null,
        dialog: {
            title: null,
            body: null,
            firstDocumentTitle: null,
            firstDocumentUrl: null,
            secondDocumentTitle: null,
            secondDocumentUrl: null,
            primaryBtnTitle: null,
            secondaryBtnTitle: null
        }
    },
    idin: {
        title: null,
        body: null,
        /**
         * illustration
         * name of image file for example: 'start.svg'
         * @type {string | null}
         */
        illustration: 'slimmemeters.svg',
        firstDocumentTitle: null,
        primaryBtnTitle: null,
        secondaryBtnTitle: null,
        dialog: {
            title: null,
            body: null,
            firstDocumentTitle: null,
            firstDocumentUrl: null,
            secondDocumentTitle: null,
            secondDocumentUrl: null,
            primaryBtnTitle: null,
            secondaryBtnTitle: null
        }
    },
    createUser: {
        title: null,
        body: null,
        primaryBtnTitle: null,
        enableLabels: true,
        secondaryBtnTitle: 'Annuleren'
    }
};