'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
const footNoteExpectedCosts = `Dit tarief is gebaseerd op de nu verwachte, gemiddelde inkoopprijs voor het komende jaar. Het tarief 
dat je werkelijk gaat betalen voor elektriciteit (verschilt per uur) en voor gas (verschilt per dag) weet 
je altijd een dag van tevoren via onze app. Wij stellen je maandbedrag nu zo nauwkeurig mogelijk vast, maar je werkelijke 
energiekosten kunnen dus hoger of lager uitvallen dan verwacht. In de app geven we je altijd inzicht in of je naar verwachting 
bij moet betalen bij de jaarrekening, of juist geld terugkrijgt.`;

// @ts-nocheck
exports.default = {
    /**
     * The suffix to show in the title
     */
    titleSuffix: ' prijsopbouw',
    elecFlexRateTitle: 'Verwacht stroomtarief per kWh',
    gasRateTitle: 'Verwacht gastarief per m³',
    dailyRateEpexTitle: 'Verwachte inkoopprijs per kWh (EPEX)',
    dailyRateLebaTitle: 'Verwachte inkoopprijs per m³ (LEBA)',

    /**
     * @type {FootNotes} found in frontend  src/types/productDetails.ts
     */
    footNotes: [{
        type: 'COST',
        text: footNoteExpectedCosts,
        costTypes: [12, 13]
    }]
};