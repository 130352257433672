'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
// @ts-nocheck
exports.default = {
    title: 'Wachtwoord wijzigen?',
    body: 'Vul hieronder het e-mailadres in om een e-mail met een wijzigingslink te ontvangen.',
    inputLabel: 'E-mailadres',
    inputPlaceholder: 'Bijv.: voorbeeld@domein.nl',
    submit: 'Stuur e-mail',
    backButtonText: 'Terug',
    showBackButton: true
};