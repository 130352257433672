'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
// @ts-nocheck
exports.default = {
    scanBarcodeHeaderText: 'Heb je alle meters gescand?',
    scanBarcodeParagraphText: 'Heb je een slimme stroom- en/of gasmeter? Zorg er dan voor dat je de barcode van al je slimme meters scant.',
    idinHeaderText: 'Identificatie met iDIN',
    idinParagraphText: `Als consument kan je jezelf nu identificeren bij andere organisaties met de 
    veilige en vertrouwde inlogmethode van jouw eigen bank.`,
    idinPrimaryButtonText: 'Identificeren',
    idinSecondaryButtonText: 'Scan slimme meter(s)',
    idinAboutLinkText: 'Meer over iDIN.',
    idinAboutLink: 'https://www.idin.nl/'
};